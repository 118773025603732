<template>
  <div id="wrapper" class="divided">

    <!-- One -->
    <section class="banner style1 orient-left content-align-left image-position-right fullscreen onload-image-fade-in onload-content-fade-right">
      <div class="content">
        <img src="/images/y_sport.png" class="service-name" alt="" />
        <p>
          Si vous êtes sportif d’élite ou simple amateur, votre assiette sera là aussi un atout majeur. Améliorer votre préparation, maximiser vos performances et éviter les blessures grâce à une récupération optimisée. Je vous accompagne au travers de vos défis sportifs pour vous aider à atteindre vos objectifs.
        </p>

        <router-link class="button" to="/prices">
            Tarifs
        </router-link>
      </div>
      <div class="image">
        <img src="/images/sport/2.jpg" alt="" />
      </div>
    </section>

    <!-- Two -->
    <section class="spotlight style1 orient-right content-align-left image-position-center onscroll-image-fade-in" id="first">
      <div class="content">
        <h2>Accompagnement en cas de :</h2>
        <ul>
        <li>  Préparation sportive d’endurance (courses, marathons)</li>
          <li>Optimisation de performances</li>
          <li>Gestion de poids et de vitalité</li>
          <li>Blessures répétées</li>
          <li>Mauvaise récupération</li>
          <li>Carences nutritionnelles</li>
        </ul>
      </div>
      <div class="image">
        <img src="/images/sport/1.jpg" alt="" />
      </div>
    </section>
  </div>
</template>
